<template src="./HallBuyByTIDaLink.html"></template>

<script>
import Hall from "@/components/hall/Hall";
import {mapGetters} from "vuex";
//import store from "@/store";
export default {
  name: "HallBuyByTIDaEID",
  components: {Hall},
  data: () => ({

    isLocalAuth: false,

    show_id:    0,
    event_id:   0,
    culture_id: 0,
    theater:    0,
    link:       "",

    //actual:false,
    show_dialog: false,
    show_dialog_info: false,
    text_dialog_info: "",

    event:{},

    eventloaded:false,

    view:2,
    options:[],

    hallConfig:{
      base:{
        type: 'user',
        mode: 'event',
        view: 'sale',
        event_id: 0,
        mo_id: 0,
      },
      options:{
        zone:true,
        discount:false,
        armour:false,
        puskin:false

      },
      additional:{
        zone_n:0,

        monger_mode: "show_all",
        monger_id:0,
        seat_status_id:0
      }
    }, //hallConfig

    order:{

      email:'',
      phone:'',
      fio:'',

      event_id:   0,
      show_id:    0,
      culture_id: 0,

      link:         true,
      payment_type: 0,

      seats:[],

      show_name:'Спектакль не выбран',

      total:{
        price:0,
        count:0,
      },

      date: 'Сегодня',


    }


  }),

  created() {

    //console.log(this.$route.params);

    if (this.$route.params.tid) {

      // входим под учеткой в этот театр
      // const username = "api_user@zakaz.cloud";
      // const password = "EubMWqYVyT3W3QFFo6b3JVQCk4wLmwo";
      // const isAdmin = false;
      // const theater_select = this.$route.params.tid;
      //
      // store.dispatch(AUTH_REQUEST, { username, password, theater_select, isAdmin }).then(() => {
      //
      //   // опознаем групповую ссылку
      //   if (this.$route.params.link){
      //
      //     this.theater= this.$route.params.tid;
      //     this.link = this.$route.params.link
      //     this.getMObyLink(this.link);
      //
      //   }
      //   else{
      //     console.log('no eid goto afisha');
      //   }

      // });

      console.log('after');

    }
    else{
      console.log('нет театра нет входа')
      return '/none'
    }


  },

  computed:{
    ...mapGetters(["getColorZones"]),

    getPricesColors(){


      let price = [];

      let prices = this.event.event.prices.split('/');

      for (let i = 0; i<this.event.price_map.zone_count; i++){

        let t = {};
        t.id = i;
        t.price = prices[i];
        t.color = this.getColorZones[i+1];

        price.push(t);
      }

      //console.log(price)

      return price;
    },




  },

  mounted() {

    console.log('mount');
  },



  methods:{

    getMObyLink(group_link){

      let post_data = {
        filter: {
          close_bux_invoice: group_link,
          type: 2
        },
        fields: {
          id: 1,
          order_cancel: 1,
          order_count: 1,
          comment: 1,
          status: 1,
          order_create: 1,
          event_id: 1,
          mog_id: 1
        }
        ,
        param: {
          sort: "id",
          desc: true,
          limit: 1
        }
        ,
        detail: {
          event_id: {
            fields: {
              show_id:  1,
              timedate: 1,
              status:   1
            },
            detail: {
              show_id: {
                fields: {
                  name: 1,
                  age:  1,
                  time: 1
                }
              }
            }

          }
        }
      };

      //console.log(post_data);

      let pr2 = this.$store.state.api.findMObyLink(post_data);

      pr2.then( data =>{

        console.log(data);

        this.event_id = data['0'].event_id;
        this.hallConfig.base.event_id = this.event_id;
        this.hallConfig.base.mo_id = data['0'].id;

        this.isLocalAuth = true;

      })

    },



    clear_order(){

      this.order = {

        event_id:   this.event_id,
        show_id:    this.show_id,
        culture_id: this.culture_id,

        payment_type: 0,

        seats:[],

        total:{
          price:0,
          count:0,
        }

      }

    },

    // eslint-disable-next-line no-unused-vars
    onSeatClick(seat_id, status){

      // console.log(seat_id, status);
      //
      // let t = this.order.seats.findIndex(seat=>(seat===seat_id));
      // console.log(t);

      let tt = this.$refs.hall.getSelected();

      let ids = [];
      let seats = [];

      let prices = this.event.event.prices.split('/');

      let t = {};
      let total = {
        count:0,
        price:0
      };

      tt.forEach(seat=>{
        //console.log(seat);
        ids.push(seat.base.id);

        t = {
          id:seat.base.id,
          zone: seat.additional.price_zone,
          price: prices[seat.additional.price_zone-1],
        }

        total.count++;
        total.price +=  parseInt(t.price)

        seats.push(t);

      })

      console.log(seats, total);


      this.order.seats = ids;
      this.order.total = total;

      // отправлять эти данные в store
      //this.$store.dispatch('updateOrder', this.order);

      this.$store.commit('updateOrder', this.order)


    },

    onReadyHall(){

      console.log('hall is ready');

      this.loadEventAdm();


    },

    onLoadedHall(event){

      console.log(event);

      if (this.$route.params.tid);

      // камерная сцена
      if (this.$route.params.tid==="5") {
        switch (this.$route.params.eid){
          case "648":
          case "649":
          case "650":
          case "651":
          case "653":
          case "654":
          case "655":
          case "656":
            this.show_dialog_info = true;
            this.text_dialog_info = "Обратите внимание, что данный спектакль будет проходить в г. Похвистнево";
            break;

          case "673":
          case "674":
            this.show_dialog_info = true;
            this.text_dialog_info = "Обратите внимание, что данный спектакль будет проходить в Г. Кинель ул.Мира, 42";
        }
      }

      // смдт
      if (this.$route.params.tid==="3") {
        switch (this.$route.params.eid){
          case "70":
            this.show_dialog_info = true;
            this.text_dialog_info = "Обратите внимание, что данный спектакль будет проходить в п. Мехзавод";
            break;
        }
      }

      console.log(event.data.event.date.actual);

      if (event.data.event.date.actual){
        this.show_dialog = false;
        console.log('!!')
      }
      else{
        this.show_dialog = true;
      }

      this.event = event.data;
      this.eventloaded = true;

      this.order.event_id   = this.event.event.id;
      this.order.culture_id = this.event.show.culture_id;
      this.order.show_id    = this.event.show.id;
      this.order.show_name  = this.event.show.name;
      this.order.date       = this.event.event.timedate;



      // let data = {
      //   view: this.hallConfig.base.view,
      //   options: this.hallConfig.options,
      //   additional: this.hallConfig.additional,
      // }
      //
      // this.$refs.hall.updateMode(data);

      this.$nextTick(() => {
        this.$refs.hall.full_redraw();
        this.$nextTick(() => {
          this.$refs.hall.full_redraw();
        })
      })

    },

    loadEventAdm(){
      //console.log('cl');

      this.selected_table = [];
      this.flagSetMongerArmour = false;

      let t = this.prepareDataHall();
      //console.log(t);

      this.$refs.hall.prepareHall(t);




    },

    updateMode: function () {

      // выбрали уполномоченых - сбросили на бронь на ПОСТАВИТЬ
      //this.EnvoyModeSetArmour = true

      this.$nextTick(() => {

        // let data = {
        //   view: this.getViewById(this.view),
        //   options: this.getOptions(this.options),
        //   additional: this.getAdditional(this.additional),
        // }

        //let t = this.prepareDataHall();

        //console.log(data);
        //this.$refs.hall.updateMode(t);


        this.$nextTick(() => {
          this.$refs.hall.full_redraw();
          this.$nextTick(() => {
            this.$refs.hall.full_redraw();
          })
        })

      })


    },

    prepareDataHall(){

      let data = {

        type: this.hallConfig.base.type,
        mode: this.hallConfig.base.mode,

        view: this.hallConfig.base.view,
        options: this.hallConfig.options,

        additional: this.hallConfig.additional,

        event_id: this.hallConfig.base.event_id,
        mo_id:    this.hallConfig.base.mo_id,
        scale:0.5

      }

      //console.log(data);

      return data;

    },



    getSelectedSeats(){

      //this.$refs.hall.getSelected(true);

    },

    clearSelected(){

      //this.$refs.hall.clearSelected();

    },

    doOrder(){

      console.log('order');

      let post_data = {

        base:{
          email:"mail",
          total:"700",
          user:1,
          mode:"kassa"
        },

        orders:[
          {
            event:1,
            price:700,

            seats:[
              7148, 7157
            ]
          }

        ]

      }
      console.log(post_data);

      let pr2 = this.$store.state.api.addOrder(post_data);

      pr2.then( data =>{

        console.log(data);
        // resp : id only
        //
        // this.layouts = data;
        // this.layouts_ready = true;
      })

    },

  }
}



</script>

<style scoped>

</style>
